<template>
	<!-- <el-dialog :title="$t('i18nn_ff6fd823ffab7e6b')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShow" width="1000px"
		top="10px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_ff6fd823ffab7e6b')" append-to-body :visible.sync="dialogShow" :direction="'rtl'" size="1000px">
	
		<!-- <div style="margin-top: 10px;">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span>{{$t('i18nn_daef27ebf42d4adb')}}</span>
					</h3>
				</div>
				<div>
					<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{$t('i18nn_3a7c1bda93906fe9')}}</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group v-model="checkedItems" @change="handleCheckedItemsChange">
						<el-checkbox v-for="(item, index) in excelHead" :label="item.key" :key="index"
							style="margin-bottom: 10px;">{{ item.title }}</el-checkbox>
					</el-checkbox-group>
				</div>
			</el-card>
		</div> -->

		<!-- <div v-if="warningMsg" style="margin-top: 10px;">
			<el-alert :title="$t('i18nn_daaaeb1b7b22b126')" type="warning" :description="warningMsg" show-icon :closable="false"></el-alert>
		</div> -->

		<div style="margin-top: 10px;">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span><span>{{$t('i18nn_ff6fd823ffab7e6b')}}</span>（<span>{{$t('i18nn_055a45ea5fef0d11')}}</span>）</span>
					</h3>
				</div>

				<!-- <div v-if="allExpDataTotal">{{$t('i18nn_73bbf51487ec69e9')}}<strong>{{ allExpDataTotal }}</strong>{{$t('i18nn_3e48b039f79c39a0')}}</div> -->

				<div style="">

					<div class="red" style="margin: 10px 0;"><span>{{$t('i18nn_26ad8f1f07292f65')}}</span>50000<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>，<span>{{$t('i18nn_21bae2bd339406af')}}</span>，<span>{{$t('i18nn_cc1f108900897a83')}}</span>...</div>
					<div style="margin: 10px 0;">
						<div v-for="(item,index) in excelSheetData" :key="index" class="sheet-msg">
							<span class="sheet-name">{{item.sheetName}}</span>：
							<span v-if="item.Data"><span>{{$t('i18nn_ff6fd823ffab7e6b')}}</span>{{item.Data.length}}<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>，</span>
							<span><span>{{$t('i18nn_73bbf51487ec69e9')}}</span>{{item.total}}<span>{{$t('i18nn_117aa3f27e5e430c')}}</span></span>
						</div>
					</div>
					<div style="padding: 10px 0;" v-loading="loading_load" :element-loading-text="$t('i18nn_b954f8829728e9d2')">
						<el-button type="primary" icon="el-icon-download" @click="exportExcel2()"
							v-if="excelSheetData && excelSheetData.length>0">
							<span>
								<span>{{$t('i18nn_83a93e396379a993')}}</span>(<span>{{$t('i18nn_5734a059a4622f20')}}</span>50000<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>)
							</span>
						</el-button>
					</div>

				</div>


			</el-card>

			<!-- <el-card style="margin-top: 10px;">
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span>分批导出所有页（筛选项数据）</span>
					</h3>
				</div>
				<div v-if="allExpDataTotal">{{$t('i18nn_73bbf51487ec69e9')}}<strong>{{ allExpDataTotal }}</strong>{{$t('i18nn_3e48b039f79c39a0')}}</div>
				<div class="red" style="margin-top: 10px;">分批导出；每批{{pageSize}}条；</div>
				<div class="expBtnCon">
					<div class="expBtn">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(0, pageSize)">
							<span>
								导出前{{pageSize}}条
							</span>
							<span v-if="'bill' == type">(含合计)</span>
						</el-button>
					</div>
					<div class="expBtn" v-for="(item,index) in batchNumList" :key="index">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(item.start, item.end)" v-if="allExpDataTotal > item.start">
							导出第{{item.start}}-{{item.end}}条</el-button>
						<span v-if="'bill' == type">(含合计)</span>
					</div>
				</div>
				
			</el-card> -->
		</div>

		<!-- <div style="margin-top: 10px;" v-if="!noShowExpDefExcel">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span>导出当前页（筛选项数据）</span>
					</h3>
				</div>
				<div>
					<el-button type="primary" icon="el-icon-download" @click="exportExcel">{{$t('i18nn_ff6fd823ffab7e6b')}}<span v-if="'bill' == this.type">(含合计)</span>
					</el-button>
					<span>当前页，共{{ excelData.length }}条</span>
				</div>
			</el-card>
		</div> -->

		<!-- <div slot="footer" class="dialog-footer"> -->
			<!-- <el-button type="primary" icon="el-icon-download" @click="exportExcel">{{$t('i18nn_c432271c995f31a5')}}</el-button> -->
			<!-- <el-button type="primary" plain @click="dialogShow = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
		<!-- </div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	// const cityOptions = [this.$t('i18nn_5fc8144f38fd1ebc'), this.$t('i18nn_9c0ca340eff4fdce'), this.$t('i18nn_cb2ec494b0a4d52c'), this.$t('i18nn_b9a4aed64afd0cf9')];

	import excelUtilsNew from '@/utils/excelUtilsNew.js';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			// isShow: {
			//   default: function() {
			//     return false;
			//   },
			//   type: Boolean
			// },
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			// excelHead: {
			// 	default: function() {
			// 		return [];
			// 	},
			// 	type: Array
			// },
			// pagination: {
			// 	default: function() {
			// 		return {};
			// 	},
			// 	type: Object
			// },
			// excelData: {
			// 	default: function() {
			// 		return [];
			// 	},
			// 	type: Array
			// },
			columns: {
				default: function() {
					return {};
				},
				type: Object
			},
			// noShowExpDefExcel: {
			// 	default: function() {
			// 		return false;
			// 	},
			// 	type: Boolean
			// },

			excelName: {
				default: function() {
					return '';
				},
				type: String
			},
			excelOption: {
				default: function() {
					return {};
				},
				type: Object
			},
			// expHttpUrl: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// },
			expHttpFilter: {
				default: function() {
					return {};
				},
				type: Object
			},
			// warningMsg: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// },
			// hasFormatter: {
			// 	default: function() {
			// 		return false;
			// 	},
			// 	type: Boolean
			// },
			// formatterData: {
			// 	type: Function
			// },
			//类型--默认列表，
			// bill-->账单合计-第二个页签放合计的数据
			// type: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// },
			// sheet2StaticData: {
			// 	default: function() {
			// 		return {
			// 			columns: {},
			// 			Data: {}
			// 		};
			// 	},
			// 	type: Object
			// }
			// fileList: {
			//   default: function() {
			//     return [];
			//   },
			//   type: Array
			// }
		},
		components: {},
		data() {
			return {
				dialogShow: false,
				// loadingUpload: false,

				// tableData: [],


				// checkAll: false,
				// checkedItems: [],
				// cities: cityOptions,
				// isIndeterminate: true,

				loading_load: false,
				// allExpData: [],
				// allExpDataTotal: 0,

				excelSheetData: [],

				// pageSize:2000,
				// batchNumList:[],
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogShow = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				//初始化
				this.excelSheetData = [];
				this.getAllBillData(0, 50000);
			},
			//过滤条件合并
			mergeFilterData(offset,limit,newFilterData){
				// let filterData = Object.assign({
				// 		offset: offset, //起始条数
				// 		limit: limit //请求的条数
				// 	},
				// 	this.expHttpFilter
				// );
				let filterData = Object.assign({
						offset: offset, //起始条数
						limit: limit //请求的条数
					}
				);
				// if(!selfFilter){
				// 	selfFilter = {};
				// }
				//仓租的筛选项
				// let selfFilter = {
				// 	feeDateStart:filterData.opTimeStart,
				// 	feeDateEnd:filterData.opTimeEnd,
				// 	startOpTime:filterData.opTimeStart,
				// 	endOpTime:filterData.opTimeEnd,
				// }
				// return Object.assign(selfFilter,newFilterData,filterData);
				return Object.assign({},newFilterData,filterData);
			},
			//加载全部账单数据
			async getAllBillData(offset, limit) {
				this.loading_load = true;
				let excelSheetData = [];
				// let filterData = Object.assign({
				// 		offset: offset, //起始条数
				// 		limit: limit //请求的条数
				// 	},
				// 	this.expHttpFilter
				// );

				// excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_21e42bbe9894aafc'), this.$urlConfig.WhBillSdsInWhPage, filterData,
				// 	this.columns.BillDropShipInWh));
				// excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_194d733acdedb737'), this.$urlConfig.WhBillSdsOpRecPage,
				// 	filterData, this.columns.BillDropShipOperation));
				// excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_9695030363addb97'), this.$urlConfig.WhBillSdsOutWhPage,
				// 	filterData, this.columns.BillDropShipOutWh));
				// excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_6b1945319eb18d5f'), this.$urlConfig.WhBillStfOpPage, filterData,
				// 	this.columns.BillTransferOrder));
				// filterData.feeDateStart = filterData.opTimeStart;
				// filterData.feeDateEnd = filterData.opTimeEnd;
				// excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_d33d0afe39b5b1a2'), this.$urlConfig.WhBillWhFeePage, filterData,
				// 	this.columns.BillStorageCharges));
				// // excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_65b02ebff64ee1a6'), this.$urlConfig.WhFeeRecordPageList,
				// // 	filterData, this.columns.BillStorageChargesDet));
				// filterData.startOpTime = filterData.opTimeStart;
				// filterData.endOpTime = filterData.opTimeEnd;
				// excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_636d7192b222277c'), this.$urlConfig.WhBillAddFeePage, filterData,
				// 	this.columns.BillAdditionalCharge));

				// this.excelSheetData = excelSheetData;
				// this.loading_load = false;
				// console.log('excelSheetData', this.excelSheetData);
				
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_21e42bbe9894aafc'), this.$urlConfig.WhBillSdsInWhPage,
					this.mergeFilterData(offset, limit, this.columns.BillDropShipInWh_filter),
					this.columns.BillDropShipInWh));
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_194d733acdedb737'), this.$urlConfig.WhBillSdsOpRecPage,
					this.mergeFilterData(offset, limit, this.columns.BillDropShipOperation_filter), 
					this.columns.BillDropShipOperation));
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_9695030363addb97'), this.$urlConfig.WhBillSdsOutWhPage,
					this.mergeFilterData(offset, limit, this.columns.BillDropShipOutWh_filter), 
					this.columns.BillDropShipOutWh));
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_6b1945319eb18d5f'), this.$urlConfig.WhBillStfOpPage, 
					this.mergeFilterData(offset, limit, this.columns.BillTransferOrder_filter),
					this.columns.BillTransferOrder));
				// filterData.feeDateStart = filterData.opTimeStart;
				// filterData.feeDateEnd = filterData.opTimeEnd;
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_d33d0afe39b5b1a2'), this.$urlConfig.WhBillWhFeePage, 
					this.mergeFilterData(offset, limit, this.columns.BillStorageCharges_filter),
					this.columns.BillStorageCharges));
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_65b02ebff64ee1a6'), this.$urlConfig.WhFeeRecordPageList,
					this.mergeFilterData(offset, limit, this.columns.BillStorageChargesDet_filter), 
					this.columns.BillStorageChargesDet));
				// filterData.startOpTime = filterData.opTimeStart;
				// filterData.endOpTime = filterData.opTimeEnd;
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_636d7192b222277c'), this.$urlConfig.WhBillAddFeePage, 
					this.mergeFilterData(offset, limit, this.columns.BillAdditionalCharge_filter),
					this.columns.BillAdditionalCharge));
				
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_21d58958f280d153'), this.$urlConfig.WhBillFTROrderPage,
					this.mergeFilterData(offset, limit, this.columns.BillTransshipmentFast_filter),
					this.columns.BillTransshipmentFast));
				
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_56b48b3b6de43bde'), this.$urlConfig.WhBillTSCCInWhPage,
					this.mergeFilterData(offset, limit, this.columns.BillTransshipmentInWh_filter),
					this.columns.BillTransshipmentInWh));
					
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_e69eafd9946571ed'), this.$urlConfig.WhBillSTRInWhPage,
					this.mergeFilterData(offset, limit, this.columns.BillTransshipmentOutWh_filter),
					this.columns.BillTransshipmentOutWh));
				
				
				
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_f80dedf7c601059f'), this.$urlConfig.WhBillADDVSInWhPage,
					this.mergeFilterData(offset, limit, this.columns.BillValueAdd_filter),
					this.columns.BillValueAdd));
				
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_5ddc19a148f5e5c1'), this.$urlConfig.WhBillSREInWhPage,
					this.mergeFilterData(offset, limit, this.columns.BillExchangeReturnInWh_filter),
					this.columns.BillExchangeReturnInWh));
				
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_5b177a1b00c63503'), this.$urlConfig.WhBillPCPUPage,
					this.mergeFilterData(offset, limit, this.columns.BillPCPU_filter),
					this.columns.BillPCPU));
				
				excelSheetData.push(await this.oneExcelSheetData(this.$t('i18nn_9a716c393c296ea8'), this.$urlConfig.WhBillTRUCKPage,
					this.mergeFilterData(offset, limit, this.columns.BillTRUCK_filter),
					this.columns.BillTRUCK));
				
				
				this.excelSheetData = excelSheetData;
				this.loading_load = false;
				console.log('excelSheetData', this.excelSheetData);
				// return;
				// this.exportExcel2();
			},
			//单个excelSheetData
			async oneExcelSheetData(name, url, filterData, sheet1_columns) {
				// let one_excelSheetData = {};
				// let sheet1_columns = [];
				// this.$notify({
				//   title: this.$t('tips.tipsTitle'),
				//   message: name+"数据正在加载...",
				// 	type:"success"
				// });
				let sheet1_http = await this.$http.put(url, filterData);
				let sheet1_http_data = sheet1_http.data;
				let sheet1_data = [];
				let total = 0;
				if (200 == sheet1_http_data.code && sheet1_http_data.rows) {
					sheet1_data = sheet1_http_data.rows;
					total = sheet1_http_data.total;

					//模拟大数据量
					// for(let i=0,ln=2;i<ln;i++){
					// 	sheet1_data = sheet1_data.concat(sheet1_data);
					// 	total = parseInt(total)+parseInt(total);
					// }
					// this.$message.success(name+"数据已准备...");
					this.$notify({
						title: this.$t('tips.tipsTitle'),
						message: name + "数据已准备...",
						type: "success"
					});
				} else {
					// this.$message.success(name+"数据有误...");
					this.$notify({
						title: this.$t('tips.tipsTitle'),
						message: name + "数据有误...",
						type: "warning"
					});
				}
				//统计数据
				let lastData = [];
				if (this.$t('i18nn_21e42bbe9894aafc') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillSdsOutInTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_194d733acdedb737') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillSdsOpTotal, filterData);
					lastData = [
						[this.$t('i18nn_e3de3b77067f6a24'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_9695030363addb97') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillSdsOutWhTotal, filterData);
					let price = '';
					let remark = '';
					if (countData.priceDto) {
						price = countData.priceDto.price;
						remark = countData.priceDto.remark;
					}
					lastData = [
						['总体积(m³)', countData.totalVol],
						['总重量(LB)', countData.totalWeight],
						[this.$t('i18nn_b1813b05de28548d'), countData.totalPallte],
						['单价(托)', price],
						[this.$t('i18nn_c65deeaa92c96268'), countData.totalFeeAmt],
						[this.$t('i18nn_15b3627faddccb1d'), remark]
					];
				} else if (this.$t('i18nn_6b1945319eb18d5f') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillStfOpTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_d33d0afe39b5b1a2') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillWhFeeTotal, filterData);
					lastData = [
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_65b02ebff64ee1a6') == name) {
					
				} else if (this.$t('i18nn_636d7192b222277c') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillAddFeeTotal, filterData);
					lastData = [
						[this.$t('i18nn_c2c4e3a31c61dc7f'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_21d58958f280d153') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillFTROrderTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_56b48b3b6de43bde') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillTSCCOutInTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_e69eafd9946571ed') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillSTROutInTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_f80dedf7c601059f') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillADDVSOutInTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_5ddc19a148f5e5c1') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillSREOutInTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_5b177a1b00c63503') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillPCPUTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				} else if (this.$t('i18nn_9a716c393c296ea8') == name) {
					let countData = await this.oneCountData(name, this.$urlConfig.WhBillTRUCKTotal, filterData);
					lastData = [
						[this.$t('i18nn_6e9c4e33131b18a7'), countData.recordCount],
						[this.$t('i18nn_da35456de5403862'), countData.totalFeeAmt]
					];
				}
				return {
					sheetName: name,
					columns: sheet1_columns,
					Data: sheet1_data,
					lastData: lastData,
					total: total
				};
			},

			//汇总数据
			async oneCountData(name, url, filterData) {
				let lastData = [];
				let sheet2_http = await this.$http.put(url, filterData);
				let sheet2_http_data = sheet2_http.data;
				let sheet2_data = {};
				if (200 == sheet2_http_data.code && sheet2_http_data.data) {
					sheet2_data = sheet2_http_data.data;
					this.$notify({
						title: this.$t('tips.tipsTitle'),
						message: name + "汇总数据已准备...",
						type: "success"
					});
				} else {
					// this.$message.success(name+"数据有误...");
					this.$notify({
						title: this.$t('tips.tipsTitle'),
						message: name + "汇总数据有误...",
						type: "warning"
					});
				}
				return sheet2_data;
			},

			exportExcel2() {
				this.loading_load = true;

				let excelSheetData = this.excelSheetData;
				excelUtilsNew.exportExcelForMultiSheet(excelSheetData, this.excelName, this.excelOption);
				// this.dialogShow = false;
				this.loading_load = false;
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.sheet-msg {
		margin: 10px 0;
	}

	.sheet-name {
		display: inline-block;
		vertical-align: middle;
		text-align: right;
		width: 100px;
	}

	.expBtnCon {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;

		.expBtn {
			margin-right: 10px;
		}
	}
</style>
