<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
				<div class="tableConTable" v-loading="loading">
					<!-- <div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
										<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
									</el-tooltip>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
							</el-col>
						</el-row>
					</div> -->

					<!-- <div> -->
					<div class="filterCon" style=";">
							<!-- <el-row type="flex" justify="end"> -->
								<!-- <el-col :span="24"> -->
					<ul class="filterConList">
					<!-- <li>
											<span><i class="red">*</i>{{ $t('i18nn_29991afa9781d554') }}</span>
											<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy>
										</li>
						
										<li>
											<span><i class="red">*</i>{{ $t('i18nn_c944a6686d996ab3') }}</span>
											<whNoSelect @changeData="changWhNo"></whNoSelect>
										</li>
						
										<li>
											<span><i class="red">*</i>{{$t('i18nn_d99d790ec4383bfb')}}</span>
											<el-date-picker
												v-model="filterData.daterange"
												size="small"
												type="daterange"
												align="right"
												:clearable="true"
												unlink-panels
												range-separator="-"
												start-placeholder="start date"
												end-placeholder="end date"
												:picker-options="pickerOptions"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd"
												@change="initData()"
											></el-date-picker>
										</li> -->
					<!-- </ul>
									<ul class="filterConList"> -->
					<!-- <li>
											<span>{{ 'undefined' }}</span>
											<el-select filterable clearable v-model="filterData.wh_op_status" :placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
												<el-option v-for="item in selectOption.wh_op_status" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code">
													<div class="sel_option_s1" style="">
														<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
														<span class="sel_option_code" style="">{{ item.code }}</span>
													</div>
												</el-option>
											</el-select>
										</li>
										<li>
											<span>isBill</span>
											<el-select filterable clearable v-model="filterData.wh_op_is_bill" :placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
												<el-option v-for="item in selectOption.statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
											</el-select>
										</li>
										<li>
											<span>SKU</span>
											<el-input
												type="text"
												v-model="filterData.goodsSku"
												size="small"
												clearable
												@keyup.enter.native="initData()"
												maxlength="50"
												:placeholder="$t('i18nn_5a9aefbc03c778f7')"
												style="width: 180px;"
											/>
										</li> -->
					<li>
											<!-- <el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('i18nn_1e7246dd6ccc5539') }}</el-button> -->
										<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
										</li>
					</ul>
					<!-- </el-col> -->
							<!-- </el-row> -->
						</div>
					<!-- </div> -->

					<div style="display:flex;align-items: center;">
						<div style="padding: 10px;">
							<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
								<el-button type="warning" icon="el-icon-download" size="mini" @click="exportExcel()">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
							</el-tooltip>
							<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
								<el-button type="primary" icon="el-icon-download" size="mini" @click="exportDetExcel()">{{$t('i18nn_c726626501fc8bc1')}}</el-button>
							</el-tooltip>
						</div>
						<el-descriptions title="" border style="width: 800px;">

							<!-- <el-descriptions-item :label="$t('i18nn_5ada761d1917f8bc')">{{ countData.totalVol }}</el-descriptions-item> -->
							<!-- <el-descriptions-item :label="$t('i18nn_ea71165e067be24e')">{{ countData.totalWeight }}</el-descriptions-item> -->
							<el-descriptions-item :label="$t('i18nn_da35456de5403862')">{{ countData.totalFeeAmt }}</el-descriptions-item>
						</el-descriptions>
					</div>


					<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
						:height="$store.state.tableMaxHeight9" @row-click="handleCurrentChange"
						@selection-change="handleSelectionChange" style="width: 100%" size="small">
						<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>



						<!-- <el-table-column label="undefined"> -->
						<!-- <el-table-column prop="plStatusName" label="undefined"></el-table-column> -->

						<!-- <el-table-column prop="opNo" label="undefined"></el-table-column> -->
						<!-- <el-table-column prop="opNo" label="undefined">
							<template slot-scope="scope">
								<span>{{ scope.row.opNo }}</span>
							</template>
						</el-table-column> -->
						<!-- whNo workNo isBillName opStatusName -->
						<el-table-column prop="whNo" :label="$t('i18nn_5acbec83efb27445')"></el-table-column>
						<!-- <el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column> -->
						<!-- <el-table-column prop="isBillName" :label="'isBillName'"></el-table-column> -->

						<!-- <el-table-column prop="opStatus" label="undefined">
							<template slot-scope="scope">
								<div>
									<el-tag v-if="'10' == scope.row.opStatus">{{ $Utils.i18nKeyText(scope.row,'opStatusName') }}</el-tag>
									<el-tag type="warning" v-else-if="'20' == scope.row.opStatus">{{ $Utils.i18nKeyText(scope.row,'opStatusName') }}</el-tag>
									<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'opStatusName') }}</el-tag>
								</div>
							</template>
						</el-table-column> -->
						<!-- <el-table-column prop="title" :label="$t('i18nn_7431e76029678ec7')"></el-table-column> -->

						<el-table-column prop="feeTypeName" :label="$t('i18nn_a4eca6ff2588d791')"></el-table-column>

						

						<!-- <el-table-column prop="totalVol" :label="$t('i18nn_5ada761d1917f8bc')"></el-table-column> -->

						<!-- <el-table-column prop="opStatusName" label="undefined"></el-table-column> -->

						<!-- <el-table-column prop="totalWeight" :label="$t('i18nn_ea71165e067be24e')"></el-table-column> -->

						<!-- <el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')"></el-table-column> -->

						<el-table-column prop="totalFeeAmt" :label="$t('i18nn_14b9b51812811e15')"></el-table-column>
						
						<el-table-column prop="feeType" :label="$t('i18nn_51c53d67a11837ae')"></el-table-column>
						<!-- <el-table-column prop="realPlCount" label="undefined"></el-table-column> -->

						<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

						<!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->
						<el-table-column prop="feeDate" :label="$t('i18nn_375acecdfac5c204')"></el-table-column>
						<!-- <el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="180px" fixed="right">
							<template slot-scope="scope">
								<div>
									
								</div>
							</template>
						</el-table-column> -->
					</el-table>
				</div>
				<div class="tableConPagination">
					<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
				</div>
			</div>
		</div>

		<!-- 详情弹窗 -->
		<!-- <el-dialog :title="$t('i18nn_6afbe4c6dcf87a6c')" :close-on-click-modal="false" :visible.sync="dialogDetVisible" :top="'10px'" :width="'800px'">
			<div v-loading="loading_det">
				<div class="tableCon" v-loading="loading_det" element-loading-text="加载中...">
					<div class="tableConTable">
						<el-table :height="$store.state.tableMaxHeight" border :data="detData" style="width: 100%">
							<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>

							<el-table-column prop="feeTypeName" min-width="100px" :label="$t('i18nn_a4eca6ff2588d791')"></el-table-column>
							<el-table-column prop="feeAmt" min-width="100px" :label="$t('i18nn_d7315fb8114eb446')"></el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</el-dialog> -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData"
			:pagination="pagination" :excelName="excelName" :excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhBillWhFeePage" :expHttpFilter="pageFilterData()" :type="'bill'"
			:sheet2StaticData="sheet2StaticData"></whExcelCustom>

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime2" :excelHead="excelHead2" :excelData="expExcelData2"
			:noShowExpDefExcel="true" :pagination="{}" :excelName="excelName2" :excelOption="excelOption2"
			:expHttpUrl="$urlConfig.WhFeeRecordPageList" :expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	// import cusSelFuzzy from '@/components/StorageCenter/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import BillingRecords from '@/components/WarehouseCenter2/FinanceMana/BillingRecords.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		components: {
			// cusSelFuzzy,
			whNoSelect,
			whExcelCustom
			// BillingRecords
		},

		props: {
			queryTime:{},
			cusUserId: {
				default: function() {
					return '';
				},
				type: String
			},
			whNo: {
				default: function() {
					return '';
				},
				type: String
			},
			daterange: {
				default: function() {
					return [];
				},
				type: Array
			},
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				sheet2StaticData: [],

				//excel导出-明细
				excelOpenTime2: '',
				excelHead2: [],
				expExcelData2: [],
				excelName2: '',
				excelOption2: {},

				// cusUserId: '',
				// cusUserName: '',
				//打开选择框
				// dialogSelVisible: false,

				// dialogAddVisible: false,
				// selBillData: {},
				// openRowData: {},
				// openRowIndex: {},

				// dialogFormVisible: false,
				// dialogFormVisibleMsg: '',
				// dialogFormInvalidDate: false,
				// currentSel: {},
				multipleSelection: [],

				pickerOptions: this.$PickerDate.pickerOptions_1(),

				// ],
				selectOption: {
					wh_no: [],
					wh_op_status: [],
					wh_fin_bill_status: [],
					wh_op_record_type: [],
					// wh_op_is_bill:[],
					// calcType: [{
					// 		value: 'v',
					// 		label: this.$t('i18nn_7f995eb43e1fc92b')
					// 	},
					// 	{
					// 		value: 'w',
					// 		label: this.$t('i18nn_02ad74c45e9271a0')
					// 	}
					// ],
					// plStatusList: [{
					// 		value: '',
					// 		label: this.$t('i18nn_16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '1',
					// 		label: this.$t('i18nn_9cee201b82dbc9cb')
					// 	},
					// 	{
					// 		value: '2',
					// 		label: this.$t('i18nn_1ff1fd8ddb3e0e8d')
					// 	}
					// ]
				},

				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],

				loading_count: false,
				countData: {},
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//详情数据
				// loading_det: false,

				// detData: [],
				// dialogDetVisible: false,

				// form: {
				// 	id: null,

				// 	title: '', //标题',
				// 	describe: '', //this.$t('i18nn_ab6ea90b9164b20a'),
				// 	remark: '',
				// 	finRecords: [
				// 		{
				// 			finName: '', //this.$t('i18nn_a4eca6ff2588d791'),
				// 			finAmt: '' //"0",
				// 			// "id":"",//"数据ID"
				// 		}
				// 	]

				// },

				// formRules: {
				// 	title: [{ required: true, message: this.$t('i18nn_5a9aefbc03c778f7'), trigger: 'blur' }]
				// },
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序
					// calcType: 'v', //合计需要的参数;v-体积，w-重量;默认体积
					daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this
						.$Utils.fomatterDate_YYYYMMdd(new Date())
					],

					// daterange: [],
					wh_op_status: '20',
					wh_op_is_bill: '',
					// wh_op_status: '',
					// plStatus:'',
					// isBill:'',
					// billStatus: '',
					whNo: '',
					goodsSku: ''
					// plName: ''
				}
			};
		},
		watch: {
			queryTime: function(newVal, oldVal) {
				console.log('queryTime');
				this.initData();
			},
			// whNo: function(newVal, oldVal) {
			// 	console.log('whNo');
			// 	this.initData();
			// },
			// daterange: function(newVal, oldVal) {
			// 	console.log('daterange');
			// 	this.initData();
			// }
		},
		activated() {
			console.log('activated');
			// if (this.$route.query && this.$route.query.cusUserId) {
			// 	this.cusUserId = this.$route.query.cusUserId;
			// }
			// this.$nextTick(() => {
			// 	this.$refs.cusSelFuzzy.init(this.cusUserId);
			// });
			// this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.filterData.whNo = this.whNo;
				this.filterData.daterange = this.daterange;
				// if (!this.cusUserId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				if (!this.filterData.whNo) {
					this.$message.warning(this.$t('FormMsg.Select_long_wh'));
					return;
				}
				// if (!this.filterData.daterange || this.filterData.daterange.length<=0) {
				// 	this.$message.warning(this.$t('i18nn_9c4659d873c8b417'));
				// 	return;
				// }
				this.getCountData();
				this.getPageData();
				// this.getDicData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout(); //解决表格错位
				});
			},
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.cusUserId = data.userId;
			// 	// this.cusUserName = data.companyName;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },

			// changCus2(data) {
			// 	console.log('changCus', data);
			// 	this.form.cusName = data.companyName;
			// 	this.form.cusNo = data.cusNo;
			// },
			//导出excel的列名和key
			getColumns() {
				let columns = [{
						title: this.$t('i18nn_5acbec83efb27445'),
						key: 'whNo'
					},
					// {
					// 	title: this.$t('i18nn_d7315fb8114eb446'),
					// 	key: 'totalFeeAmt'
					// },

					{
						title: this.$t('i18nn_46c3f6e0f657e7a3'),
						key: 'feeTypeName',
					},
					// {
					// 	title: '费用类型Code',
					// 	key: 'feeType',
					// },
					{
						title: this.$t('i18nn_5ada761d1917f8bc'),
						key: 'totalVol',
					},
					{
						title: this.$t('i18nn_ea71165e067be24e'),
						key: 'totalWeight',
					},
					{
						title: this.$t('i18nn_14b9b51812811e15'),
						key: 'totalFeeAmt',
					},
					{
						title: this.$t('i18nn_375acecdfac5c204'),
						key: 'feeDate',
					},
				];

				return columns;
			},
			//导出 excel
			exportExcel() {
				let columns = this.getColumns();

				// if (!this.cusUserId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				if (!this.filterData.whNo) {
					this.$message.warning(this.$t('FormMsg.Select_long_wh'));
					return;
				}
				// if (!this.filterData.daterange || this.filterData.daterange.length<=0) {
				// 	this.$message.warning(this.$t('i18nn_9c4659d873c8b417'));
				// 	return;
				// }

				let Data = this.tableData;

				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = '仓租_BillStorageCharges';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();

			},

			//导出excel的列名和key
			getColumns2() {
				let columns = [{
						title: this.$t('i18nn_5acbec83efb27445'),
						key: 'whNo'
					}, 
					// {
					// 	title: this.$t('i18nn_209fdd8f47e20fbf'),
					// 	key: 'cusNo'
					// },
					// {
					// 	title: this.$t('i18nn_29991afa9781d554'),
					// 	key: 'cusName'
					// },
					{
						title: this.$t('i18nn_46c3f6e0f657e7a3'),
						key: 'feeTypeName'
					},
					{
						title: this.$t('i18nn_d7315fb8114eb446'),
						key: 'feeAmt'
					},
					{
						title: this.$t('i18nn_3775920d44088b96'),
						key: 'putWhCount',
						types: 'custom',
						formatter: function(row) {
							// <div v-if="scope.row.feeDescriptionJson">
							//   <div>
							//     <span>{{$t('3775920d44088b96')}}</span>
							//     <span>{{scope.row.feeDescriptionJson.putWhCount}}</span>
							//   </div>
							//   <div>
							//     <span>{{$t('96e86c7f83dd7561')}}</span>
							//     <span>{{scope.row.feeDescriptionJson.whFeeTypeName}}</span>
							//   </div>
							//   <div v-if="scope.row.feeDescriptionJson.remark">
							//     <span>{{$t('15b3627faddccb1d')}}</span>
							//     <span>{{scope.row.feeDescriptionJson.remark}}</span>
							//   </div>
							// </div>
							return row.feeDescriptionJson.putWhCount;
						}
					},
					{
						title: this.$t('i18nn_4d3765c415bb4462'),
						key: 'whAge',
						types: 'custom',
						formatter: function(row) {
							return row.feeDescriptionJson.whAge;
						}
					},
					{
						title: this.$t('i18nn_96e86c7f83dd7561'),
						key: 'whFeeTypeName',
						types: 'custom',
						formatter: function(row) {
							return row.feeDescriptionJson.price + ' ' + row.feeDescriptionJson.whFeeTypeName;
						}
					},
					{
						title: '计费周期(天)',
						key: 'calcCycle',
						types: 'custom',
						formatter: function(row) {
							return  row.feeDescriptionJson.calcCycle;
						}
					},
					{
						title: this.$t('i18nn_9bfcf061ccdd5c1c'),
						key: 'feeDate'
					},

					{
						title: this.$t('i18nn_3a6eb04ef8f3327b'),
						key: 'place'
					},
					{
						title: this.$t('i18nn_e0c16ac094cc2bce'),
						key: 'goodsSku',
						types: 'custom',
						formatter: function(row) {
							return row.feeDescriptionJson.goodsSku;
						}
					},
					{
						title: 'SKU名称',
						key: 'goodsName',
						types: 'custom',
						formatter: function(row) {
							return row.feeDescriptionJson.goodsName + ' ' + row.feeDescriptionJson.goodsNameEn;
						}
					},
					{
						title: this.$t('i18nn_0e240c5802d17fea'),
						key: 'vol',
						types: 'custom',
						formatter: function(row) {
							return row.feeDescriptionJson.vol;
						}
					},
					{
						title: this.$t('i18nn_9eaa906ebc446062'),
						key: 'inWhDate',
						types: 'custom',
						formatter: function(row) {
							return row.feeDescriptionJson.inWhDate;
						}
					},
					{
						title: this.$t('i18nn_15b3627faddccb1d'),
						key: 'remark',
						types: 'custom',
						formatter: function(row) {
							return row.feeDescriptionJson.remark;
						}
					}
				];

				return columns;
			},
			//导出明细
			exportDetExcel() {
				let columns = this.getColumns2();
				// let Data = this.tableData;

				// let data_dom = JQ('#ex_table').find(".el-table__body");
				// let obj_key_img = {};
				// // console.log(data_dom.find("tr"));
				// data_dom.find("tr").each((index, domEle)=>{
				// 	// console.log("index",index);
				// 	let imgObj = JQ(domEle).find("td .cell img");
				// 	// console.log("imgObj",imgObj);
				// 	if(imgObj){
				// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
				// 	}
				// })
				// // console.log("obj_key_img",obj_key_img);
				// Data.forEach(item=>{
				// 	item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
				// })
				this.expExcelData2 = [];
				this.excelHead2 = columns;
				this.excelName2 = '仓租明细_BillStorageChargesDetails';
				this.excelOption2 = {
					height: 20
				};
				this.excelOpenTime2 = new Date().getTime();
			},
			//分页的筛选项数据
			pageFilterData() {
				let startTime = '';
				let endTime = '';
				if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
					startTime = this.filterData.daterange[0];
					endTime = this.filterData.daterange[1];
				} else {
					// this.$message.warning(this.$t('tips.Please_Sel_date'));
				}
				return {
					proxyUserId: this.cusUserId ? this.cusUserId : null,
					// userId: this.cusUserId,
					// plStatus: this.filterData.plStatus? this.filterData.plStatus:null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// workNo: this.filterData.workNo ? this.filterData.workNo : null,
					// wh_op_is_bill: this.filterData.wh_op_is_bill? this.filterData.wh_op_is_bill:null,
					// isBill: this.filterData.wh_fin_bill_status ? this.filterData.wh_fin_bill_status : null,
					// opStatus: this.filterData.wh_op_status ? this.filterData.wh_op_status : null,
					// opNo: this.filterData.opNo ? this.filterData.opNo : null,
					// isBill: this.filterData.wh_op_is_bill ? this.filterData.wh_op_is_bill : null,
					feeDateStart: startTime ? startTime : null,
					feeDateEnd: endTime ? endTime : null,
					goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
					// bizType: this.filterData.bizType ? this.filterData.bizType : null,
					// "plName": this.filterData.plName? this.filterData.plName:null,
					// "goodsType": this.queryParamObj.goodsType
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目
					},
					this.pageFilterData()
				);

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhBillWhFeePage, filterData)
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},
			//请求合计数据
			getCountData() {
				let filterData = Object.assign({},
					this.pageFilterData()
				);

				this.loading_count = true;
				this.$http
					.put(this.$urlConfig.WhBillWhFeeTotal, filterData)
					.then(({
						data
					}) => {
						this.loading_count = false;
						if (200 == data.code) {
							//表格显示数据
							this.countData = data.data;
							// this.sheet2StaticData = {
							// 	sheetName: "sheet2",
							// 	columns: [{
							// 		title: this.$t('i18nn_da35456de5403862'),
							// 		key: 'totalFeeAmt',
							// 	}, ],
							// 	Data: [this.countData]
							// };
							this.sheet2StaticData = [
								[this.$t('i18nn_da35456de5403862'),this.countData.totalFeeAmt]
							];
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading_count = false;
					});
			},
			//打开详情弹窗
			// getDetListData(id) {
			// 	this.loading_det = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhFeeBillOpRecordDetList + '/' + id + '/fees', {})
			// 		.then(({ data }) => {
			// 			this.loading_det = false;
			// 			if (200 == data.code && data.data && data.data.feeList) {
			// 				//表格显示数据
			// 				this.detData = data.data.feeList;
			// 				// this.form.finRecords = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_9c0ace2b5d32f74c'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('请求数据有误！');
			// 			this.loading_det = false;
			// 		});
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},
			//废弃
			// AbandonedAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('AbandonedAction', row);
			// 	let parm = [];

			// 	if (row) {
			// 		// 单条
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 		if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('7b80e66b535a3732'));
			// 			return;
			// 		}

			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 	}
			// 	this.$confirm('确定废弃吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhFeeBillOpRecordAbandoned, parm, () => {
			// 				this.initData();
			// 				this.$message.success(this.$t('i18nn_f66fc986e2ae222f'));
			// 			});
			// 		})
			// 		.catch(() => {});
			// },

			//查看费用明细
			// showDetAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('showDetAction', row);
			// 	this.dialogDetVisible = true;
			// 	this.detData = [];
			// 	this.getDetListData(row.id);
			// },

			//重新计算
			// recalculateAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('completeAction', row);
			// 	let parm = [];

			// 	if (row) {
			// 		// 单条
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 		if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('7b80e66b535a3732'));
			// 			return;
			// 		}

			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 	}

			// 	this.postData(this.$urlConfig.WhFeeBillOpRecordClac, parm, () => {
			// 		this.initData();
			// 		this.$message.success(this.$t('9f30371831a98237'));
			// 	});
			// },

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_op_status'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_op_is_bill = data.data['wh_op_is_bill'];
			// 				this.selectOption.wh_op_status = data.data['wh_op_status'];
			// 				// this.selectOption.wh_op_type = data.data['wh_op_type'];
			// 				// this.selectOption.wh_fin_bill_status = data.data['wh_fin_bill_status'];
			// 				// this.selectOption.wh_fin_bill_status = data.data['wh_fin_bill_status'];
			// 				// this.selectOption.wh_op_record_type = data.data['wh_op_record_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// @import url(@/assets/css/service_mod.less);
</style>
