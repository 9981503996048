//格式化计算后的数据
// sf_001_container： 柜型 containerSize
// sf_001_pallte：SKU-goodsSKu  QT-总托数 
// sf_001_package：SKU-goodsSku 每箱重量-ctnWeight  QT-总箱数
// sf_001_over_qt：柜型-containerSize  QT-总箱数
// sf_001_over_wt：SKU-goodsSku  每箱重-ctnWeight QT-总箱数
// sf_001_over_category：柜型-containerSize  SKU种类型数-skuCategoryCount
// sf_001_live_update：柜型-containerSize  是否现场卸货- liveUpdate 
// sf_002_order_pl：SKU-goodsSku  重量-goodsWeight  件数-QT
// sf_004_order_label：SKU-goodsSku  重量-goodsWeight 件数-QT
// sf_020_pallte：托盘数-palletCount
// sf_020_package：SKU-goodsSku 重量-ctnWeight  箱数-sendCTN
// sf_021_pallte: 总托数-palletCount
// sf_021_pallte_label：总托数-palletCount

//仅针对表格显示，key可重复
function formatCalcKeyName(type) {
	let re = [];
	if ('sf_001_container' == type) {
		re = [{
			key: "containerSize",
			name: "柜型(in)",
		}];
	} else if ('sf_001_pallte' == type) {
		re = [{
				key: "goodsSku",
				name: "SKU"
			},
			{
				key: "QT",
				name: "总托数"
			}
		];
	} else if ('sf_001_package' == type) {
		re = [{
				key: "goodsSku",
				name: "SKU"
			},
			{
				key: "ctnWeight",
				name: "每箱重量(LB)"
			}, {
				key: "QT",
				name: "总箱数"
			}
		];
	} else if ('sf_001_over_qt' == type) {
		re = [{
			key: "containerSize",
			name: "柜型(in)"
		}, {
			key: "QT",
			name: "总箱数"
		}];
	} else if ('sf_001_over_wt' == type) {
		re = [{
				key: "goodsSku",
				name: "SKU"
			},
			{
				key: "ctnWeight",
				name: "每箱重(LB)"
			}, {
				key: "QT",
				name: "总箱数"
			}
		];
	} else if ('sf_001_over_category' == type) {
		re = [{
			key: "containerSize",
			name: "柜型(in)"
		}, {
			key: "skuCategoryCount",
			name: "SKU种类型数"
		}];
	} else if ('sf_001_live_update' == type) {
		re = [{
			key: "containerSize",
			name: "柜型(in)"
		}, {
			key: "liveUpdate",
			name: "是否现场卸货"
		}];
	} else if ('sf_002_order_pl' == type) {
		re = [{
				key: "goodsSku",
				name: "SKU"
			},
			{
				key: "goodsWeight",
				name: "重量(LB)"
			}, {
				key: "QT",
				name: "件数"
			}
		];
	} else if ('sf_004_order_label' == type) {
		re = [{
				key: "goodsSku",
				name: "SKU"
			},
			{
				key: "goodsWeight",
				name: "重量(LB)"
			}, {
				key: "QT",
				name: "件数"
			}
		];
	} else if ('sf_020_pallte' == type) {
		re = [{
			key: "palletCount",
			name: "托盘数"
		}];
	} else if ('sf_020_package' == type) {
		re = [{
				key: "goodsSku",
				name: "SKU"
			},
			{
				key: "ctnWeight",
				name: "重量(LB)"
			}, {
				key: "sendCTN",
				name: "箱数"
			}
		];
	} else if ('sf_021_pallte' == type) {
		re = [{
			key: "palletCount",
			name: "总托数"
		}];
	} else if ('sf_021_pallte_label' == type) {
		re = [{
			key: "palletCount",
			name: "总托数"
		}];
	} else if ('sf_002_order_pl_pallte' == type) {
		re = [{
			key: "palletCount",
			name: "总托数"
		}];
	} else if (type.lastIndexOf('_addSF')>-1) {
		re = [{
			key: "QT",
			name: "数量"
		}];
	}  else {

	}
	return re;
}
//仅针对表格显示，key可重复
export function formatCalcData(calcJsonObj, type) {
	let re = formatCalcKeyName(type);

	re.forEach(item => {
		if (calcJsonObj && calcJsonObj.condition) {
			item.value = calcJsonObj.condition[item.key];
			if ('sf_001_live_update' === type && 'liveUpdate' === item.key) {
				if ('0' === item.value) {
					item.value = "否";
				} else if ('1' === item.value) {
					item.value = "是";
				}
			}
		}
	});

	if (calcJsonObj && calcJsonObj.price) {
		re.push({
			key: "price_condition",
			name: "报价",
			value: calcJsonObj.price.price
		});
	}
	if (calcJsonObj && calcJsonObj.price) {
		re.push({
			key: "price_remark",
			name: "报价备注",
			value: calcJsonObj.price.remark
		});
	}
	return re;
}
//针对表格下载，key不可重复
export function formatCalcExcelData(type) {
	// let re = formatCalcKeyName(type);
	let re = [];
	// re.forEach(item => {
	// 	item.title = item.name;
	// 	item.type = "custom";
	// 	item.formatter = function(row){
	// 		if('sf_001_live_update'=== row.feeType && 'liveUpdate'===item.key){
	// 			if('0'===item.value){
	// 				return "否";
	// 			} else if('1'===item.value){
	// 				return "是";
	// 			}
	// 		} else {
	// 			if(row && row.calcJsonObj && row.calcJsonObj.condition){
	// 				return row.calcJsonObj.condition[item.key];
	// 			}
	// 		}
	// 		return "";
	// 	}
	// });

	re.push({
		key: "price_condition",
		title: "报价",
		types: "custom",
		formatter: function(row) {
			if (row && row.calcJsonObj && row.calcJsonObj.price) {
				return row.calcJsonObj.price.price;
			} else {
				return "";
			}
		}
	});
	re.push({
		key: "goodsSku_condition",
		title: "SKU",
		types: "custom",
		formatter: function(row) {
			if (row && row.calcJsonObj && row.calcJsonObj.condition) {
				return row.calcJsonObj.condition.goodsSku;
			} else {
				return "";
			}
		}
	});
	//收货入库，柜型单独列出
	if('BillDropShipInWh'==type){
		re.push({
			key: "containerSize_condition",
			title: "柜型n)",
			types: "custom",
			formatter: function(row) {
				if (row && row.calcJsonObj && row.calcJsonObj.condition) {
					return row.calcJsonObj.condition.containerSize;
				} else {
					return "";
				}
			}
		});
	}
	
	re.push({
		key: "calcOther_condition",
		title: "其他计算参数",
		types: "custom",
		formatter: function(row) {
			if (row && row.calcJsonObj && row.calcJsonObj.condition) {
				let reValArr = [];
				// let reValArr = formatCalcKeyName(row.calcJsonObj.condition.feeType);
				// let reValStrArr = reValArr.map(item=>{
				// 	return item.name+":"+row.calcJsonObj.condition[item.name];
				// });
				if (row.calcJsonObj.condition.containerSize) {
					reValArr.push("柜型n)" + ":" + row.calcJsonObj.condition.containerSize)
				}
				if (row.calcJsonObj.condition.QT) {
					let type = row.feeType;
					let name = "";
					if ('sf_001_pallte' == type) {
						name = "总托数";
					} else if ('sf_001_package' == type) {
						name = "总箱数";
					} else if ('sf_001_over_qt' == type) {
						name = "总箱数";
					} else if ('sf_001_over_wt' == type) {
						name = "总箱数";
					} else {
						name = "件数";
					}
					reValArr.push(name + ":" + row.calcJsonObj.condition.QT);
				}
				if (row.calcJsonObj.condition.ctnWeight) {
					reValArr.push("每箱重量(LB)" + ":" + row.calcJsonObj.condition.ctnWeight)
				}
				if (row.calcJsonObj.condition.skuCategoryCount) {
					reValArr.push("SKU种类" + ":" + row.calcJsonObj.condition.skuCategoryCount)
				}
				if (row.calcJsonObj.condition.liveUpdate) {
					let liveUpdateVal = "";
					if ('0' === row.calcJsonObj.condition.liveUpdate) {
						liveUpdateVal = "否";
					} else if ('1' === row.calcJsonObj.condition.liveUpdate) {
						liveUpdateVal = "是";
					}
					reValArr.push("是否现场卸货" + ":" + liveUpdateVal)
				}
				if (row.calcJsonObj.condition.goodsWeight) {
					reValArr.push("重量(LB)" + ":" + row.calcJsonObj.condition.goodsWeight)
				}
				if (row.calcJsonObj.condition.palletCount) {
					reValArr.push("托数" + ":" + row.calcJsonObj.condition.palletCount)
				}
				if (row.calcJsonObj.condition.sendCTN) {
					reValArr.push("箱数" + ":" + row.calcJsonObj.condition.sendCTN)
				}
				return reValArr.join(" \n");
			} else {
				return "";
			}
		}
	});
	return re;
}
