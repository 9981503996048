<template>
	<div class="">
		<div>
			<!-- <div class="tableConTop">
				<el-row>
					<el-col :span="5" class="tableConTopLeft">
						<h3>
							<span class="tct_tit">{{$t('i18nn_62d1f13b854eb1f7')}}</span>
							<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
								<el-button type="warning" circle icon="el-icon-download" size="mini"
									@click="exportExcel()"></el-button>
							</el-tooltip>
						</h3>
					</el-col>
					<el-col :span="19" class="tableConTopRig">
						<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
					</el-col>
				</el-row>
			</div> -->
			<div style="display: flex;justify-content: space-between; align-item:center;">
				<div>
					<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
						<el-button type="warning" icon="el-icon-download" size="mini"
							@click="exportExcel()">{{$t('i18nn_83a93e396379a993')}}</el-button>
					</el-tooltip>
				</div>
				<div>
					<div class="filterCon" style="">
						<ul class="filterConList">
							<li>
								<!-- <span>
									<i class="red">*</i>
									{{ $t('i18nn_29991afa9781d554') }}
								</span>
								<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy> -->
								
								<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
								<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
							</li>
					
							<li>
								<span>
									<i class="red">*</i>
									{{ $t('i18nn_c944a6686d996ab3') }}
								</span>
								<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect>
							</li>
					
							<li>
								<span>
									<i class="red">*</i>{{$t('i18nn_d99d790ec4383bfb')}}</span>
								<el-date-picker v-model="filterData.daterange" size="small" type="daterange" align="right"
									:clearable="true" unlink-panels range-separator="-" start-placeholder="start date"
									end-placeholder="end date" :picker-options="pickerOptions" format="yyyy-MM-dd"
									value-format="yyyy-MM-dd" @change="changDaterange"></el-date-picker>
							</li>
						</ul>
					</div>
				</div>
			</div>
			
		</div>

		<el-tabs v-model="activeName" type="border-card">
			<el-tab-pane :label="$t('i18nn_21e42bbe9894aafc')" name="first">
				<span slot="label">
					<i class="el-icon-coin"></i>{{$t('i18nn_21e42bbe9894aafc')}}</span>
				<BillDropShipInWh ref="BillDropShipInWh" v-show="'first'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillDropShipInWh>
			</el-tab-pane>
			<el-tab-pane :label="$t('i18nn_194d733acdedb737')" name="second">
				<span slot="label">
					<i class="el-icon-coin"></i>{{$t('i18nn_194d733acdedb737')}}</span>
				<BillDropShipOperation ref="BillDropShipOperation" v-show="'second'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillDropShipOperation>
			</el-tab-pane>
			<el-tab-pane :label="$t('i18nn_9695030363addb97')" name="third">
				<span slot="label">
					<i class="el-icon-coin"></i>{{$t('i18nn_9695030363addb97')}}</span>
				<BillDropShipOutWh ref="BillDropShipOutWh" v-show="'third'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillDropShipOutWh>
			</el-tab-pane>
			<el-tab-pane :label="$t('i18nn_6b1945319eb18d5f')" name="fourth">
				<span slot="label">
					<i class="el-icon-coin"></i>{{$t('i18nn_6b1945319eb18d5f')}}</span>
				<BillTransferOrder ref="BillTransferOrder" v-show="'fourth'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillTransferOrder>
			</el-tab-pane>
			<el-tab-pane :label="$t('i18nn_5151fd7875058ddf')" name="fifth">
				<span slot="label">
					<i class="el-icon-coin"></i>{{$t('i18nn_5151fd7875058ddf')}}</span>
				<BillStorageCharges ref="BillStorageCharges" v-show="'fifth'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillStorageCharges>
			</el-tab-pane>
			<el-tab-pane :label="$t('i18nn_636d7192b222277c')" name="sixth">
				<span slot="label">
					<i class="el-icon-coin"></i>{{$t('i18nn_636d7192b222277c')}}</span>
				<BillAdditionalCharge ref="BillAdditionalCharge" v-show="'sixth'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillAdditionalCharge>
			</el-tab-pane>
			
			<el-tab-pane :label="$t('i18nn_21d58958f280d153')" name="BillTransshipmentFast">
				<span slot="label">
					<i class="el-icon-coin"></i>
					<span>{{$t('i18nn_21d58958f280d153')}}</span>
				</span>
				<BillTransshipmentFast ref="BillTransshipmentFast" v-show="'BillTransshipmentFast'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillTransshipmentFast>
			</el-tab-pane>
			
			<el-tab-pane :label="''" name="seven">
				<span slot="label">
					<i class="el-icon-coin"></i>
					<span>{{$t('i18nn_56b48b3b6de43bde')}}</span>
				</span>
				<BillTransshipmentInWh ref="BillTransshipmentInWh" v-show="'seven'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillTransshipmentInWh>
			</el-tab-pane>
			<el-tab-pane :label="''" name="eight">
				<span slot="label">
					<i class="el-icon-coin"></i>
					<span>{{$t('i18nn_e69eafd9946571ed')}}</span>
				</span>
				<BillTransshipmentOutWh ref="BillTransshipmentOutWh" v-show="'eight'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillTransshipmentOutWh>
			</el-tab-pane>
			
			
			
			<el-tab-pane :label="$t('i18nn_f80dedf7c601059f')" name="nine">
				<span slot="label">
					<i class="el-icon-coin"></i>
					<span>{{$t('i18nn_f80dedf7c601059f')}}</span>
				</span>
				<BillValueAdd ref="BillValueAdd" v-show="'nine'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillValueAdd>
			</el-tab-pane>
			
			<el-tab-pane :label="$t('i18nn_5ddc19a148f5e5c1')" name="ten">
				<span slot="label">
					<i class="el-icon-coin"></i>
					<span>{{$t('i18nn_5ddc19a148f5e5c1')}}</span>
				</span>
				<BillExchangeReturnInWh ref="BillExchangeReturnInWh" v-show="'ten'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillExchangeReturnInWh>
			</el-tab-pane>
			
			<!-- 港口提柜 PCPU -->
			<el-tab-pane :label="$t('i18nn_5b177a1b00c63503')" name="BillPCPU">
				<span slot="label">
					<i class="el-icon-coin"></i>{{$t('i18nn_5b177a1b00c63503')}}</span>
				<BillPCPU ref="BillPCPU" v-show="'BillPCPU'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillPCPU>
			</el-tab-pane>
			<!-- 尾程卡派 TRUCK -->
			<el-tab-pane :label="$t('i18nn_9a716c393c296ea8')" name="BillTRUCK">
				<span slot="label">
					<i class="el-icon-coin"></i>{{$t('i18nn_9a716c393c296ea8')}}</span>
				<BillTRUCK ref="BillTRUCK" v-show="'BillTRUCK'==activeName" :queryTime="RenderQueryStartTime" :cusUserId="filterData.agentUser"
					:whNo="filterData.whNo" :daterange="filterData.daterange"></BillTRUCK>
			</el-tab-pane>
			
		</el-tabs>

		<!-- excel 导出排序 -->
		<whExcelByBill :openTime="excelOpenTime" :columns="excelColumns" :excelName="'仓库账单_WhBill'"
			:excelOption="{ height: 20 }" :expHttpFilter="pageFilterData()">
		</whExcelByBill>

	</div>
</template>

<script>
	// import cusSelFuzzy from '@/components/StorageCenter/components/cusSelFuzzy.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';

	import BillDropShipOperation from '@/components/StorageCenter/FinanceMana/BillModule/BillDropShipOperation.vue';
	import BillDropShipOutWh from '@/components/StorageCenter/FinanceMana/BillModule/BillDropShipOutWh.vue';
	import BillDropShipInWh from '@/components/StorageCenter/FinanceMana/BillModule/BillDropShipInWh.vue';

	import BillTransferOrder from '@/components/StorageCenter/FinanceMana/BillModule/BillTransferOrder.vue';
	import BillStorageCharges from '@/components/StorageCenter/FinanceMana/BillModule/BillStorageCharges.vue';
	import BillAdditionalCharge from '@/components/StorageCenter/FinanceMana/BillModule/BillAdditionalCharge.vue';
	
	import BillTransshipmentInWh from '@/components/StorageCenter/FinanceMana/BillModule/BillTransshipmentInWh.vue';
	import BillTransshipmentOutWh from '@/components/StorageCenter/FinanceMana/BillModule/BillTransshipmentOutWh.vue';
	import BillValueAdd from '@/components/StorageCenter/FinanceMana/BillModule/BillValueAdd.vue';
	
	import BillExchangeReturnInWh from '@/components/StorageCenter/FinanceMana/BillModule/BillExchangeReturnInWh.vue';
	
	import BillTransshipmentFast from '@/components/StorageCenter/FinanceMana/BillModule/BillTransshipmentFast.vue';
	
	import BillPCPU from '@/components/StorageCenter/FinanceMana/BillModule/BillPCPU.vue';
	import BillTRUCK from '@/components/StorageCenter/FinanceMana/BillModule/BillTRUCK.vue';
	
	import {
		formatCalcData,
		formatCalcExcelData
	} from '@/components/StorageCenter/FinanceMana/BillModule/caclBill.js';

	import whExcelByBill from '@/components/StorageCenter/components/whExcelByBill.vue';

	export default {
		props: {
			parm: {
				default: function() {
					return {
						userId: '', //"用户ID",
						goodsSKu: '', //this.$t('i18nn_74ed28f373ea14da'),
						whNo: '' //this.$t('i18nn_5acbec83efb27445')
					};
				},
				type: Object
			},
		},
		components: {
			SelAgentUser,
			whNoSelect,
			BillDropShipOperation,
			BillDropShipOutWh,
			BillDropShipInWh,
			BillTransferOrder,
			BillStorageCharges,
			BillAdditionalCharge,
			whExcelByBill,
			BillTransshipmentInWh,
			BillTransshipmentOutWh,
			BillValueAdd,
			BillExchangeReturnInWh,
			BillTransshipmentFast,
			BillPCPU,
			BillTRUCK
		},
		watch: {
			parm: {
				handler(newVal, oldVal) {
					console.log('parm',this.parm);
					this.initData();
				},
				deep: true
			}
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelColumns: {},

				activeName: 'first',
				pickerOptions: this.$PickerDate.pickerOptions_1(),
				//开始查询渲染子组件数据
				RenderQueryStartTime:"",
				// cusUserId: '',
				//查询，排序方式
				filterData: {
					daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this
						.$Utils.fomatterDate_YYYYMMdd(new Date())
					],
					agentUser:"",
					whNo: ''
				}
			};
		},
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// console.log("mounted");
		},
		methods: {
			initData() {
				// if(this.$route.query && this.$route.query.cusId && this.$route.query.whNo && this.$route.query.daterange){
				// 	this.cusUserId = this.$route.query.cusId;
				// 	this.filterData.whNo =this.$route.query.whNo;
				// 	this.filterData.daterange = this.$route.query.daterange.split(",");
				// 	this.$nextTick(()=>{
				// 		this.$refs.cusSelFuzzy.init(this.cusUserId);
				// 		this.$refs.whNoSelect.init(this.filterData.whNo);
				// 	});
				// }
				if(this.parm && this.parm.whNo && this.parm.daterange){
					console.log('parm',this.parm);
					// this.cusUserId = this.parm.cusId;
					this.filterData.whNo =this.parm.whNo;
					this.filterData.daterange = this.parm.daterange.split(",");
					console.log('daterange',this.filterData.daterange);
					this.$nextTick(()=>{
						this.$refs.cusSelFuzzy.init(this.filterData.agentUser);
						this.$refs.whNoSelect.init(this.filterData.whNo);
					});
					this.RenderQueryStartTime = new Date().getTime();
				}
			},
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.cusUserId = data.userId;
			// 	// this.RenderQueryStartTime = new Date().getTime();
			// 	// this.cusUserName = data.companyName;
			// 	this.initData();
			// },
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.RenderQueryStartTime = new Date().getTime();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				this.RenderQueryStartTime = new Date().getTime();
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			changDaterange() {
				// console.log('changWhNo', data);
				// this.filterData.whNo = data.code;
				this.RenderQueryStartTime = new Date().getTime();
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			//分页的筛选项数据
			pageFilterData() {
				let startTime = '';
				let endTime = '';
				if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
					startTime = this.filterData.daterange[0];
					endTime = this.filterData.daterange[1];
				} else {
					// this.$message.warning(this.$t('tips.Please_Sel_date'));
				}
				return {
					// userId: this.cusUserId,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// plStatus: this.filterData.plStatus? this.filterData.plStatus:null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// workNo: this.filterData.workNo ? this.filterData.workNo : null,
					// wh_op_is_bill: this.filterData.wh_op_is_bill? this.filterData.wh_op_is_bill:null,
					// isBill: this.filterData.wh_fin_bill_status ? this.filterData.wh_fin_bill_status : null,
					// opStatus: this.filterData.wh_op_status ? this.filterData.wh_op_status : null,
					// opNo: this.filterData.opNo ? this.filterData.opNo : null,
					// isBill: this.filterData.wh_op_is_bill ? this.filterData.wh_op_is_bill : null,
					opTimeStart: startTime ? startTime : null,
					opTimeEnd: endTime ? endTime : null,
					// opType:this.filterData.wh_op_type ? this.filterData.wh_op_type : null,
					// relationNo: this.filterData.relationNo ? this.filterData.relationNo : null
					// bizType: this.filterData.bizType ? this.filterData.bizType : null,
					// "plName": this.filterData.plName? this.filterData.plName:null,
					// "goodsType": this.queryParamObj.goodsType
				};
			},
			//导出 excel
			exportExcel() {
				// let columns = [

				// ];
				// //合并计算的导出
				// let caclColumns = formatCalcExcelData();
				// columns = columns.concat(caclColumns);

				// if (!this.cusUserId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				if (!this.filterData.whNo) {
					this.$message.warning(this.$t('FormMsg.Select_long_wh'));
					return;
				}
				if (!this.filterData.daterange || this.filterData.daterange.length <= 0) {
					this.$message.warning(this.$t('i18nn_9c4659d873c8b417'));
					return;
				}

				// 	let Data = this.tableData;

				// 	this.expExcelData = Data;
				// 	this.excelHead = columns;
				// 	this.excelName = '收货入库_BillDropShipInWh';
				// 	this.excelOption = { height: 20 };
				let excelColumns = {};

				this.$nextTick(item => {
					
					// if(this.$refs.BillDropShipInWh){
						// let sheet1Col = this.$refs.BillDropShipInWh.getColumns();
						// excelColumns.push(sheet1Col);
						excelColumns.BillDropShipInWh = this.$refs.BillDropShipInWh.getColumns();
						// excelColumns.BillDropShipInWh = excelColumns.BillDropShipInWh?excelColumns.BillDropShipInWh:{};
						
						excelColumns.BillDropShipInWh_filter = this.$refs.BillDropShipInWh.pageFilterData();
						// excelColumns.BillDropShipInWh_filter = excelColumns.BillDropShipInWh_filter?excelColumns.BillDropShipInWh_filter:{};
					// } else {
						// excelColumns.push({});
					// }
					// if(this.$refs.BillDropShipOperation){
						// let sheet1Col = this.$refs.BillDropShipOperation.getColumns();
						// excelColumns.push(sheet1Col);
						excelColumns.BillDropShipOperation = this.$refs.BillDropShipOperation.getColumns();
						// excelColumns.BillDropShipOperation = excelColumns.BillDropShipOperation?excelColumns.BillDropShipOperation:{};
						excelColumns.BillDropShipOperation_filter = this.$refs.BillDropShipOperation.pageFilterData();
						// excelColumns.BillDropShipOperation_filter = excelColumns.BillDropShipOperation_filter?excelColumns.BillDropShipOperation_filter:{};
					// } else {
						// excelColumns.push({});
					// }
					// if(this.$refs.BillDropShipOutWh){
						// let sheet1Col = this.$refs.BillDropShipOutWh.getColumns();
						// excelColumns.push(sheet1Col);
						excelColumns.BillDropShipOutWh = this.$refs.BillDropShipOutWh.getColumns();
						// excelColumns.BillDropShipOutWh = excelColumns.BillDropShipOutWh?excelColumns.BillDropShipOutWh:{};
						excelColumns.BillDropShipOutWh_filter = this.$refs.BillDropShipOutWh.pageFilterData();
						// excelColumns.BillDropShipOutWh_filter = excelColumns.BillDropShipOutWh_filter?excelColumns.BillDropShipOutWh_filter:{};
					// } else {
						// excelColumns.push({});
					// }
					// if(this.$refs.BillTransferOrder){
						// let sheet1Col = this.$refs.BillTransferOrder.getColumns();
						// excelColumns.push(sheet1Col);
						excelColumns.BillTransferOrder = this.$refs.BillTransferOrder.getColumns();
						// excelColumns.BillTransferOrder = excelColumns.BillTransferOrder?excelColumns.BillTransferOrder:{};
						excelColumns.BillTransferOrder_filter = this.$refs.BillTransferOrder.pageFilterData();
						// excelColumns.BillTransferOrder_filter = excelColumns.BillTransferOrder_filter?excelColumns.BillTransferOrder_filter:{};
					// } else {
						// excelColumns.push({});
					// }
					// if(this.$refs.BillStorageCharges){
						// let sheet1Col = this.$refs.BillStorageCharges.getColumns();
						// excelColumns.push(sheet1Col);
						excelColumns.BillStorageCharges = this.$refs.BillStorageCharges.getColumns();
						// excelColumns.BillStorageCharges = excelColumns.BillStorageCharges?excelColumns.BillStorageCharges:{};
						excelColumns.BillStorageCharges_filter = this.$refs.BillStorageCharges.pageFilterData();
						
						excelColumns.BillStorageChargesDet = this.$refs.BillStorageCharges.getColumns2();
						// excelColumns.BillStorageChargesDet = excelColumns.BillStorageChargesDet?excelColumns.BillStorageChargesDet:{};
						excelColumns.BillStorageChargesDet_filter = this.$refs.BillStorageCharges.pageFilterData();
					// } else {
						// excelColumns.push({});
					// }
					// if(this.$refs.BillAdditionalCharge){
						// let sheet1Col = this.$refs.BillAdditionalCharge.getColumns();
						// excelColumns.push(sheet1Col);
						excelColumns.BillAdditionalCharge = this.$refs.BillAdditionalCharge.getColumns();
						// excelColumns.BillAdditionalCharge = excelColumns.BillAdditionalCharge?excelColumns.BillAdditionalCharge:{};
						excelColumns.BillAdditionalCharge_filter = this.$refs.BillAdditionalCharge.pageFilterData();
					// } else {
						// excelColumns.push({});
					// }
					//转运入库
					excelColumns.BillTransshipmentInWh = this.$refs.BillTransshipmentInWh.getColumns();
					excelColumns.BillTransshipmentInWh_filter = this.$refs.BillTransshipmentInWh.pageFilterData();
					
					//转运出库
					excelColumns.BillTransshipmentOutWh = this.$refs.BillTransshipmentOutWh.getColumns();
					excelColumns.BillTransshipmentOutWh_filter = this.$refs.BillTransshipmentOutWh.pageFilterData();
					
					//增值服务
					excelColumns.BillValueAdd = this.$refs.BillValueAdd.getColumns();
					excelColumns.BillValueAdd_filter = this.$refs.BillValueAdd.pageFilterData();
					
					//退货入库
					excelColumns.BillExchangeReturnInWh = this.$refs.BillExchangeReturnInWh.getColumns();
					excelColumns.BillExchangeReturnInWh_filter = this.$refs.BillExchangeReturnInWh.pageFilterData();
					
					//快速转运
					excelColumns.BillTransshipmentFast = this.$refs.BillTransshipmentFast.getColumns();
					excelColumns.BillTransshipmentFast_filter = this.$refs.BillTransshipmentFast.pageFilterData();
					
					//港口提柜 PCPU
					excelColumns.BillPCPU = this.$refs.BillPCPU.getColumns();
					excelColumns.BillPCPU_filter = this.$refs.BillPCPU.pageFilterData();
					
					//尾程卡派 TRUCK
					excelColumns.BillTRUCK = this.$refs.BillTRUCK.getColumns();
					excelColumns.BillTRUCK_filter = this.$refs.BillTRUCK.pageFilterData();
					
				});
				console.log('excelColumns', excelColumns);
				this.excelColumns = excelColumns;

				this.excelOpenTime = new Date().getTime();

			},
		}
	};
</script>

<style scoped lang="less">
// @import url(@/assets/css/service_mod.less);
</style>
